import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  Application,
  Booking,
  BookingLine,
  EORFeeWithApplicationResponse,
  LanguageSkill,
  ProductFee,
  EOR
} from '@idp-education/ors-test-taker-bff-client-v1';
import { keyBy, omit } from 'lodash';
import { noop } from 'lodash';
import { SharedStateService } from '../../shared-state.service';
export const skillsLabel: { [key in LanguageSkill]: string } = { L: 'Listening', R: 'Reading', W: 'Writing', S: 'Speaking' };
@Component({
  selector: 'app-eor-request-form',
  templateUrl: './eor-request-form.component.html',
  styleUrls: ['./eor-request-form.component.scss']
})
export class EorRequestFormComponent implements OnChanges {

  @Output() onFormValidityChanged = new EventEmitter<{ value: string[], isValid: boolean }>();
  @Output() osrBookingLineId = new EventEmitter<string>();
  @Input('eorFee') EORFee: EORFeeWithApplicationResponse;
  @Input() application: Application;
  @Input() excemptionDetails: any;
  isProceedButtonEnabled = false;
  osrBookingLineIdVal;
  feeToggle = false;
  checkboxClicked: boolean;
  termsAndConditionChecked = false;
  isCheckBoxChecked = false;
  pageEnable = false;
  formData: UntypedFormGroup;
  languageSkill = [];
  productFee: ProductFee;
  lrwBookingLines: BookingLine[];
  speakingBookingLines: BookingLine[];
  totalAmount: any;
  get hasEOR() {
    return !!this?.application?.EOR || this.isOsrApp;
  }
  get isOsrApp() {
    return this.EORFee?.application?.bookings.length === 1;
  }
  _noop = noop;
  private readonly skillsLabel: { [key in LanguageSkill]: string } = skillsLabel;
  toFix = (num: number) => num ? num.toFixed(2) : '';
  hasLRW(): boolean {
    return !!this?.lrwBookingLines?.length;
  }
  hasSpeaking(): boolean {
    return !!this?.speakingBookingLines?.length;
  }
  constructor(private fb: UntypedFormBuilder, private sharedState: SharedStateService) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.EORFee?.currentValue?.eorFee) {
      const [lrw, speaking] = this.sharedState.getSpeakingAndLRW(this.EORFee?.application);
      this.productFee = this.EORFee.eorFee?.activeFee;
      this.totalAmount = this.EORFee.eorFee?.activeFee.totalAmount.toString();
      const testFee = {
        amount: this.productFee?.baseAmount,
        description: 'Re-mark fee'
      };
      this.productFee.charges = [testFee, ... this.productFee.charges];
      this.lrwBookingLines = lrw?.bookingLines;
      this.speakingBookingLines = speaking?.bookingLines;
      this.prepareForm();
      this.formData.statusChanges.subscribe(() => {
        Object.keys(this.formData.value).forEach(key => {
          if (this.formData.value[key] && key !== 'policy') {
            const skill = this.skills?.find((val) =>
              val.label === key
            )
            this.formData.value[key] = skill?.value;
            if (this.isOsrApp) {
              this.formData?.get(key).setValue(skill?.value);
            }
          }
        }
        );
        this.onFormValidityChanged.emit({
          value: this.isOsrApp ? this.formData.getRawValue() : this.formData.value,
          isValid: this.formData.valid
        });
      });
      if (this.osrBookingLineIdVal) {
        this.osrBookingLineId.emit(this.osrBookingLineIdVal);
      }
      if (this.isOsrApp) {
        this.skills?.forEach(skill => {
          const formControl = this.formData?.get(this.getLanguageSkill(skill.key as LanguageSkill));
          if (formControl && !formControl.disabled) {
            this.formData?.get(this.getLanguageSkill(skill.key as LanguageSkill)).disable();
          }
        });
      }
    } else {
      console.info('Bx Error: wrong data');
    }
  }
  changePolicy() {
    if (this.formData) {
      this?.formData?.get('policy')?.setValue(this.hasEOR);
    }
  }

  prepareForm() {
    let status;
    const languageSkill = (ls: LanguageSkill) => {
      let id: string = this.lrwBookingLines?.find(lb => lb.languageSkill === ls)?.id;
      id = id || this.speakingBookingLines?.find(sb => sb.languageSkill === ls)?.id;
      if (this.EORFee?.application?.bookings.length === 1) {
        this.EORFee?.application?.bookings[0].bookingLines?.find(i => {
          if (i.id === id) {
            status = i.status;
            if (i.status === 'ACTIVE') {
              this.osrBookingLineIdVal = i.id;
            }
          }
        });
      }
      return (this.EORFee?.application?.bookings.length > 1 && this?.application?.EOR?.bookingLineIds.some(i => i === id)) ||
        (this.EORFee?.application?.bookings.length === 1 && status === 'ACTIVE');
    };
    const checkExistSkill = (ls: LanguageSkill) => !!this?.application?.EOR || this.EORFee?.application?.bookings.length === 1 ?
      languageSkill(ls) : false;
    this.formData = this.fb.group({
      [this.skillsLabel.L]: checkExistSkill('L'),
      [this.skillsLabel.R]: checkExistSkill('R'),
      [this.skillsLabel.S]: checkExistSkill('S'),
      [this.skillsLabel.W]: checkExistSkill('W'),
      policy: new UntypedFormControl(false, [Validators.requiredTrue])
    }, {
      validators: this.requireCheckboxesToBeCheckedValidator()
    });
    setTimeout(() => {
      this.formData.get('policy').setValue(this.hasEOR);
    });
  }
  getLanguageSkill(e: LanguageSkill) {
    return this.skillsLabel[e];
  }
  getControl(e: LanguageSkill) {
    return this.formData.get(this.getLanguageSkill(e));
  }
  onFeeToggleClick() {
    if (!this.feeToggle) {
      this.feeToggle = true;
    }
    else {
      this.feeToggle = false;
    }
  }
  identify(index, action) {
    return action.label;
  }
  get skills(): { label: string, key: string, value: string }[] {
    const skillToLine = (e: LanguageSkill): BookingLine => [...this.speakingBookingLines, ...this.lrwBookingLines]
      .filter(i => i.languageSkill === e)[0];
    return Object.keys(this.skillsLabel).map((key: LanguageSkill) => ({
      label: this.skillsLabel[key],
      key,
      value: skillToLine(key)?.id,
    }));
  }

  requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: UntypedFormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach(key => {
        if (formGroup.get(key).value && key !== 'policy') {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireOneCheckboxToBeChecked: true,
        };
      }
      return formGroup.invalid ? {
        ...omit(formGroup.errors, 'requireOneCheckboxToBeChecked')
      } : null;
    };
  }

  shouldSkip(item: any): boolean {
    return (item.key === LanguageSkill.L && this.excemptionDetails?.listening) || (item.key === LanguageSkill.R && this.excemptionDetails?.reading) || (item.key === LanguageSkill.W && this.excemptionDetails?.writing) || (item.key === LanguageSkill.S && this.excemptionDetails?.speaking)
}

}
