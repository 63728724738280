<feature-toggle-provider [features]="featureFlags">
    <div class="flexbox flex-column align-items-center align-items-lg-start">
      <div class="title">{{title}} <span *ngIf="title === 'Image 1'" class="pt-1 ms-0 required-star custom-validation-error">* </span></div>
        <div class="upload-box-wrapper">
          <ng-container *ngIf="completeUpload">
            <app-document-upload-form-actions id="upload_{{idNumber}}"
              [deletableImage]="deletableImage"
              [editableImage]="editableImage"
              [editImageState]="editImageState"
              (deleteUpload)="deleteUpload()"
              (editIdDocumentImage)="editIdDocumentImage(chooseUploadTypeModal)"
              (onCropImage)="onCropImage()"
              (transformImage)="transformImage($event)"
            ></app-document-upload-form-actions>
          </ng-container>
          <div #uploadBox
              id="'upload_'{{idNumber}}"
               class="upload-box"
               [class.bg-secondary]="!visibleButtons && hasUploadError"
               [class.completeUpload]="completeUpload">
            <ng-container *ngIf="visibleButtons">
              <input type="file" hidden #inputfile (change)="onInputFileChange($event)" accept=".png, .jpg, .jpeg" multiple="false">
              <app-upload-type
                [hasWebcam]="hasWebcam"
                (openCameraModal)="openCameraModal()"
                (onUploadClick)="onUploadClick()"
              ></app-upload-type>
            </ng-container>

              <ng-container *ngIf="!visibleButtons && !hasUploadError && !completeUpload">
                  <app-upload-status
                    [percent]="percent"
                    (abortUpload)="abortUpload()"
                  ></app-upload-status>
              </ng-container>
              <div *ngIf="!visibleButtons && hasUploadError"
                  class="flexbox flex-column justify-content-center align-items-center  text-light w-75">
                  <span class="material-icons" i18n>
                      error_outline
                  </span>
                  <span class="small" *ngIf="uploadError" i18n>{{ uploadError }}</span>
                  <span class="small text-center" *ngIf="!uploadError">
                      Something went wrong <br> and we couldn't upload your passport
                  </span>
                  <div class="flexbox justify-content-center align-items-center w-100 mt-2">
                      <button *ngIf="errorCode !== MAX_FILE_SIZE" class="btn btn-blue w-100 ms-1"
                          (click)="tryAgainUpload()">
                          <span class="material-icons" i18n>
                              refresh
                          </span>
                          <span i18n>Try again</span>
                      </button>
                  </div>
              </div>
          </div>
        </div>
      
    </div>
</feature-toggle-provider>

<ng-template #chooseUploadTypeModal let-modal>
  <div class="modal-body">
    <app-upload-type
      [hasWebcam]="hasWebcam"
      (openCameraModal)="modalService.dismissAll(); openCameraModal(); cameraOpenedForUpdate = true"
      (onUploadClick)="modalService.dismissAll(); newFileInput.click()"
    ></app-upload-type>
  </div>
</ng-template>

<input type="file" hidden #newFileInput (change)="onInputFileChange($event)" accept=".png, .jpg, .jpeg" [multiple]="false">

<app-confirm-modal #deletePhotoModal title="Are you sure you wish to delete the Secondary Image?"
    imageUrl="../../../../assets/images/Girl green organic shape.png">
</app-confirm-modal>
