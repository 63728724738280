<div>
  <div class="my-0 mx-3">
    <div class="flexbox header-box mb-3">
      <div class="d-flex align-items-center">
        <button
          *ngIf="paymentStatus !== 'complete'"
          class="btn btn-link btn-focus ms-0 px-0 mt-3 me-3"
          (click)="onBackButtonClicked()"
        >
          <img src="../../../../assets/images/back-button-ssr.svg" alt="back icon" />
        </button>
        <h2 class="underlined-title">Re-mark request form</h2>
      </div>
    </div>
  </div>
  <div class="content">
    <app-wizard [steps]="steps" #wizard *ngIf="paymentStatus !== 'complete'">
      <app-step-template>
        <ng-container *ngIf="eorFeeWithApplicationResponse && application">
          <app-eor-request-form
            [eorFee]="eorFeeWithApplicationResponse"
            [application]="application"
            (onFormValidityChanged)="validationChanged($event)"
            (osrBookingLineId)="getOsrBookingLineId($event)"
            [excemptionDetails]="excemptionDetails"
          >
          </app-eor-request-form>
        </ng-container>
      </app-step-template>
      <app-step-template>
        <div
          *ngIf="registerResponse"
          class="payment-card-wrapper d-flex justify-content-center flex-column align-items-center"
        >
          <div *ngIf="paymentMethods.length > 0" class="payment-card-wrapper">
            <app-payment
              [paymentMethods]="paymentMethods"
              [paymentType]="'EOR'"
              [isStripeForwardingFailed]="isStripeForwardingFailed"
              (onCreditCardLoad)="onCreditCardLoad($event)"
            >
              <ng-template paymentPanel="TIPS">
                <app-tips
                  [application]="application"
                  [applicationPaymentId]="registerResponse.paymentId"
                  [applicationId]="registerResponse.appId"
                  [paymentType]="'EOR'"
                >
                </app-tips>
              </ng-template>
              <ng-template paymentPanel="STRIPE">
                <app-stripe
                  [application]="application"
                  [applicationPaymentId]="registerResponse.paymentId"
                  [applicationId]="registerResponse.appId"
                  [paymentType]="'EOR'"
                  (onReturn)="onReturnStripe($event)"
                >
                </app-stripe>
              </ng-template>
              <ng-template paymentPanel="CREDIT_CARD">
                <app-payment-card
                  isPaymentCard
                  [applicationPaymentId]="registerResponse.paymentId"
                  [applicationId]="registerResponse.appId"
                  #paymentCard
                  (onCreatedBraintree)="onCreateBraintree()"
                  (onValidityChange)="validationChangedPayment($event)"
                  (onCreateError)="onCreateError()"
                >
                </app-payment-card>
              </ng-template>
              <ng-template paymentPanel="OFFLINE">
                <app-offline-wizard
                  isPaymentCard
                  (onSuccessPayment)="onPaymentSuccess(application)"
                  [userName]="getApplicationUserName$ | async"
                  [receiptNumber]="receiptNumber"
                  [applicationPaymentId]="applicationPaymentId"
                  stepTwoCustomButtonLabel="Continue"
                  [showBackButton]="false"
                ></app-offline-wizard>
              </ng-template>
              <ng-template paymentPanel="PAYPAL" *ngIf="clientId">
                <app-paypal
                  isPaymentCard
                  [currency]="payCode"
                  [clientId]="clientId"
                  [purchaseItems]="purchaseItem"
                  [purchaseTotal]="purchaseTotal"
                  (onApproval)="onApprovePaypal($event)"
                  (onError)="onErrorPaypal($event)"
                  [applicationId]="registerResponse.appId"
                  [profileId]="profileId"
                  [applicationPaymentId]="registerResponse.paymentId"
                >
                </app-paypal>
              </ng-template>
              <ng-template paymentPanel="VERITRANS">
                <app-veritrans
                [application]="application"
                [tcPaymentMethod]="tcPaymentMethod"
                  [applicationPaymentId]="registerResponse.paymentId"
                  [applicationId]="registerResponse.appId"
                  [paymentType]="'EOR'">
                </app-veritrans>
              </ng-template>
              <ng-template paymentPanel="PAYSTACK">
                <app-paystack [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="registerResponse.paymentId"
                  [applicationId]="registerResponse.appId" [application]="application" [paymentType]="'EOR'">
                </app-paystack>
              </ng-template>
              <ng-template paymentPanel="BANKALFALAH">
                <app-bank-alfalah [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="registerResponse.paymentId"
                  [applicationId]="registerResponse.appId" [application]="application" [paymentType]="'EOR'">
                </app-bank-alfalah>
              </ng-template>
              <ng-template paymentPanel="BRAC">
                <app-brac [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="registerResponse.paymentId"
                  [applicationId]="registerResponse.appId" [application]="application" [paymentType]="'EOR'" [isBracCyberSource]="bracCyberSource">
                </app-brac>
              </ng-template>
              <ng-template paymentPanel="ESEWA">
                <app-e-sewa [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="registerResponse.paymentId"
                  [applicationId]="registerResponse.appId" [application]="application" [paymentType]="'EOR'">
                </app-e-sewa>
              </ng-template>
              <ng-template paymentPanel="PAYDOLLAR">
                <app-paydollar [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="registerResponse.paymentId"
                  [applicationId]="registerResponse.appId" [application]="application" [paymentType]="'EOR'">
                </app-paydollar>
              </ng-template>
              <ng-template paymentPanel="NEWEBPAY">
                <app-newebpay [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="registerResponse.paymentId"
                  [applicationId]="registerResponse.appId" [application]="application" [paymentType]="'EOR'">
                </app-newebpay>
              </ng-template>
              <ng-template paymentPanel="INIPAY">
                <app-inipay [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="registerResponse.paymentId"
                  [applicationId]="registerResponse.appId" [application]="application" [paymentType]="'EOR'">
                </app-inipay>
              </ng-template>
              <ng-template paymentPanel="HSBCOMNI">
                <app-hsbc-omni [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="registerResponse.paymentId"
                  [applicationId]="registerResponse.appId" [application]="application" [paymentType]="'EOR'">
                </app-hsbc-omni>
              </ng-template>
            </app-payment>
          </div>
        </div>
      </app-step-template>
      <div footer class="">
        <app-two-button
          [acceptText]="acceptText"
          *ngIf="creditCardLoad || isStepOne"
          [acceptClass]="
            paymentStatus !== 'error'
              ? 'btn-payAction  w-auto'
              : 'btn-errorAction  w-auto'
          "
          [rejectText]="'Cancel'"
          [enableAcceptClick]="
            enableAcceptClick &&
            paymentStatus === 'default' &&
            addressDetailsForm.valid
          "
          (acceptClick)="isStepOne ? onAcceptClick() : OnPayClick()"
          (rejectClick)="backToMyTests()"
        >
        </app-two-button>
      </div>
    </app-wizard>
    <div
      class="bottom-section"
      *ngIf="
        isStepTwo && paymentStatus !== 'complete' && creditCardLoad
      "
    >
      <div class="wrapper">
        <span class="sub-copy" i18n
          >By proceeding, you agree to the Terms and Conditions</span
        >
        <div class="braintree">
          <span class="material-icons icons-security"> security </span>
          <i class="fa fa-lock icons-lock"></i>
          <img
            src="assets/images/payment/Icon-payment-braintree.svg"
            alt="Payment Icon"
            class="braintree-img"
          />
          <span class="braintree-text" i18n
            >Payment is secured via an SSL 256bit encrypted gateway</span
          >
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column justify-content-center align-items-center m-2"
      style="gap: 10px"
      *ngIf="paymentStatus === 'complete'"
    >
      <ng-container *ngIf="isOfflinePayment$(true) | async">
        <img src="assets/images/Boy listening.png" alt="Payment successful" />
        <div>Payment successful</div>
      </ng-container>
      <ng-container *ngIf="isOfflinePayment$(false) | async">
        <img
          src="assets/images/mdi_office-building-marker-outline.svg"
          alt=""
        />
        <div class="mt-4 mb-2 offline-message">
          Please finalise payment according to instructions previously provided
          and confirm with your test centre
        </div>
      </ng-container>
      <button class="btn btn-payAction w-25" (click)="onFinishClick()">
        OK
      </button>
    </div>
  </div>
</div>
<app-confirm-modal
  #paymentErrorModalPaypal
  title="Payment Error"
  [showReject]="true"
  [acceptText]="'Close'"
  (onAcceptClicked)="onAcceptClicked()"
  imageClass="fa fa-exclamation-triangle fa-5x text-warning"
></app-confirm-modal>
