import { Injectable } from '@angular/core';

@Injectable()
export class Configuration {

  public static DATE_FORMAT = 'YYYY-MM-DD';

  public static UTC_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

  public static TIME_FORMAT = 'HH:mm:ss';

  public static TIME_REGEX = new RegExp('^((?:\\d+\\.)?(?:[01]\\d|2[0-3]):[0-5]\\d:[0-5]\\d$)');
  public static navColours = ['#94ebeb', '#cceeee', '#fff1e0', '#ffe0bf', '#f7c5cd', '#eccbcb', '#eef9d9', '#d1ebc4'];
  public static navActiveColours = ['#00abac', '#00abac', '#ff9323', '#ff9323', '#b52f2f', '#eccbcb', '#eef9d9', '#d1ebc4'];

  public static VERITRANS_POPJS_URL = 'https://pay3.veritrans.co.jp/pop/v1/javascripts/pop.js';

  baseUrl = 'http:// localhost:4400';
}
