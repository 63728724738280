import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetLatestPageUrl } from '../booking/store/booking.actions';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { PrepareSurveyFormService } from './prepare-survey-form.service';
import {
  UserProfileAddressDetails,
  UserProfileResponse,
  Gender,
  IdentificationType,
  Nationality,
  DashboardItemStatus
} from '@idp-education/ors-test-taker-bff-client-v1';
import { delay, first, map, switchMap, tap } from 'rxjs/operators';
import { setUserDetails, setUserFTime } from 'src/app/store/user/user.actions';
import {
  MultiActionButtonComponent
} from 'src/app/shared/components/multi-action-button/multi-action-button.component';
import { User } from '../account/signup/userProfile.model';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { Storage } from 'aws-amplify';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { ISections } from './models';
import { MapperService } from './mapper.service';
import { Title, Meta } from '@angular/platform-browser';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { IState } from '../onboarding/store/onboarding.reducer';
import { ID_CONSTANTS } from '../onboarding/onboarding.contants';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { ADDRESS_REGEXP, DATE_REGEXP, IDENTITYNO_REGEXP, SPL_CHAR_REGEXP } from 'src/app/shared/sharedRegex';
import { DateTime } from 'luxon';
import { postValidator } from 'src/app/shared/validators/post-validator';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { nameValidator } from 'src/app/shared/validators/custom-validators';
import { Is3Image } from '../my-tests/update-application-details/update-application-details.component';
import { getUserProfileName } from 'src/app/store/user/user.reducer';
import { titleGenderValidator } from 'src/app/shared/validators/gender-title.validator';
import { emptyWhiteSpaceValidator } from 'src/app/shared/validators/empty-whitespace-validator';
import { getCountryCode } from 'shared/utils/phone-number-helper';
import { DobDateCheckerHelper } from 'shared/helper/dob-date-checker.helper';
import { ExpiryDateValidatorsHelper } from 'shared/helper/expiry-date-validation.helper';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
  providers: [PrepareSurveyFormService]
})
export class MyProfileComponent implements OnInit, OnDestroy {
  callToActions = [];
  addressDetailsForm: UntypedFormGroup;
  identityDetailForm: UntypedFormGroup;
  personalInformationForm: UntypedFormGroup;
  surveyForm: UntypedFormGroup;
  personalUserTitle: { name: string, value: string }[] = [
    { name: 'Mr', value: 'MR' },
    { name: 'Mrs', value: 'MRS' },
    { name: 'Miss', value: 'MISS' },
    { name: 'Dr', value: 'DR' },
  ];
  personalGenderList: Gender[] = [];
  personalFieldsName: string[];
  subscription: Subscription = new Subscription();
  userProfile: UserProfileResponse;
  identityImage = null;
  identityImageObservable: Subject<Is3Image> = new Subject();
  IdentificationTypes: Subject<IdentificationType[]> = new Subject();
  getUserProfileName$ = this.store.select(getUserProfileName);
  newIdentity = false;
  newIdentityExpanded = false;


  @ViewChild('uploadPassContainer') uploadPassContainer: ElementRef;
  @ViewChild('uploadPassContent') uploadPassContent: ElementRef;
  testReasonList: any[];
  languages: any[];
  educationLevelList: any[];
  occupationLevelList: any[];
  countryList: any[];
  occupationSectorList: any[];
  studyEnglishYearsList: Array<{
    id: string,
    name: string
  }> = [];
  nationalities: Array<Nationality> = [];
  mapperService: MapperService;
  userName;
  state: IState;
  selectedIdType: IdentificationType;
  showIdAlertInfo = false;
  showUpdateAlertInfo = false;
  idAlertMsg = 'Please ensure you are using the required ID document for your latest test booking';
  selectedCountryISO = '';

  constructor(
    private store: Store<{ bookingStore, onboardingStore, userStore }>,
    private fb: UntypedFormBuilder,
    private userProfileService: UserProfileService,
    private renderer: Renderer2,
    private loadingService: LoadingService,
    private dashboardService: DashboardService,
    private apiService: ApiService,
    private prepareSurveyForm: PrepareSurveyFormService,
    private titleService: Title, private metaTagService: Meta,
    private accConfig: NgbAccordionConfig) {
    this.accConfig.closeOthers = true;
    this.titleService.setTitle('My Profile | IDP IELTS');
    this.store.dispatch(SetLatestPageUrl({ latestPage: null }));
    this.loadingService.increaseLoadingCounter();
    this.subscription.add(this.userProfileService?.getUserProfile()
    .pipe(tap((data) => {
      if (data) {
        this.userProfile = data;
        prepareSurveyForm.userProfile = this.userProfile;
        this.prepareForms();
      }
    }), switchMap(data =>
      this.prepareSurveyForm.prepareSurveyForm().pipe(map(surveyForm => ({
        uProfile: data,
        surveyForm
      })))
    ), tap(({ uProfile, surveyForm }) => {
      if (uProfile) {
        this.surveyForm = surveyForm;
        this.mapperService.surveyForm = this.surveyForm;
        this.fillLists();
        this.fillPendingTypeId();
        this.fillIdentityImage();
        this.setSelectedIdType();
      }
    }), switchMap(data => {
      this.loadingService.resetLoadingCounter();
      return this.surveyForm.valueChanges;
    }))
    .subscribe((values) => {
      const regex = SPL_CHAR_REGEXP;
      if (values && values.locationStudyEnglish && regex.test(values.locationStudyEnglish)) {
        const locationStudyEnglish = values.locationStudyEnglish.replace(regex, '');
        this.surveyForm.get('locationStudyEnglish').setValue(locationStudyEnglish);
      }
    }, err => this.loadingService.decreaseLoadingCounter()));
    this.getNationalities();
    this.subscription.add(this.store.select(appStore => appStore.userStore?.userDetails).subscribe((userDetails) => {
      this.userName = userDetails?.firstName;
    }));
    this.subscription.add(this.store.select(appStore => appStore.onboardingStore).subscribe((state) => {
      this.state = state;
      if (!this.selectedIdType && !this.newIdentity) {
        this.setSelectedIdType();
      }
    }));
  }

  fillIdentityImage() {
    if (this.userProfile?.identityDetails) {
      const s3Url = this.userProfile?.identityDetails?.s3Url;
      const version = this.userProfile?.identityDetails?.version;
      this.identityImage = {s3Url: s3Url, version: version} ;
      this.identityImageObservable.next({primaryId: this.identityImage});
    }
  }

  private setSelectedIdType() {
    if (Array.isArray(this.state?.allIdentificationType) && this.state?.allIdentificationType.length && this.userProfile) {
      this.selectedIdType = this.state?.allIdentificationType?.find(i => {
        return i?.id === this.userProfile?.identityDetails?.identificationTypeId;
      });
    }
  }

  private fillLists() {
    this.languages = this.prepareSurveyForm.languages;
    this.occupationLevelList = this.prepareSurveyForm.occupationLevelList;
    this.occupationSectorList = this.prepareSurveyForm.occupationSectorList;
    this.testReasonList = this.prepareSurveyForm.testReasonList;
    this.studyEnglishYearsList = this.prepareSurveyForm.studyEnglishYearsList;
    this.countryList = this.prepareSurveyForm.countryList;
    this.educationLevelList = this.prepareSurveyForm.educationLevelList;
  }

  private prepareForms() {
    this.addressDetailsForm = this.prepareAddressForm();
    this.identityDetailForm = this.prepareIdentityDetailForm();
    this.personalFieldsName = User.fieldNamesAsArray();
    this.personalInformationForm = this.preparePersonalInformationForm();
    this.setPersonalInfoFormValidator();
    this.mapperService = new MapperService(this.userProfile,
      this.addressDetailsForm,
      this.identityDetailForm,
      this.identityImageObservable,
      this.personalInformationForm,
      this.personalInformationForm,
      this.IdentificationTypes);
    this.identityDetailForm.disable();

  }
  private setPersonalInfoFormValidator() {
    this.personalInformationForm?.setValidators(this.getPersonalInformationValidators());
  }

  async getPhoto(key: string): Promise<string> {
    const photo = await Storage.get(key) as string;
    return photo;
  }

  ngOnInit() {
    this.metaTagService.updateTag(
      {
        name: 'description',
        content: 'Check available IELTS online test dates, your IELTS test results. Book your IELTS test with IDP today.'
      },
    );
    this.getAllDashboardApplications();
  }

  private getAllDashboardApplications() {
    this.dashboardService.getAllDashboardApplications().subscribe(apps => {
      this.showUpdateAlertInfo = !!apps.upcomingTests.length;
      this.showIdAlertInfo = !!apps.upcomingTests.filter(test => test.status === DashboardItemStatus.PAIDBUTINCOMPLETE).length;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getIdEvent() {
    return {primaryId: {s3Url: '', amzVersion: ''}};
  }

  onShow(event: string) {
    const number = this.personalInformationForm?.get('mobileNumber');
    if(event === 'profileInfo') {
      const telInput = number?.value?.number;
      if (telInput) {
        this.personalInformationForm?.get('mobileNumber').setValue(telInput.replaceAll(' ', ''));
      }
    }
  }
  private fillPendingTypeId() {
    this.subscription.add(this.apiService.GetIdentificationType.pipe(first()).subscribe(data => {
      if (Array.isArray(data) && data.length) {
        this.IdentificationTypes.next(data);
      }
    }));
  }

  modifiedS3Url(imageUrl: string) {
    if (imageUrl?.length > 0) {
      const indx = imageUrl.lastIndexOf('/');
      const fileName = imageUrl.substring(indx + 1);
      return fileName;
    }
    return null;
  }

  uploadedIdentityImage(e) {
    this.identityImage = e;
    const updatedIdentity = {primaryId: { s3Url: e.URL, amzVersion: e.amzVersion }};
    this.identityImageObservable.next(updatedIdentity);
  }

  deletedIdentityImage() {
    this.identityImage = null;
    this.identityImageObservable.next(this.getIdEvent());
  }

  generateNewIdentity() {
    this.identityImage = null;
    this.identityImageObservable.next(this.getIdEvent());
    this.newIdentity = true;
    this.renderer.setStyle(this.uploadPassContainer.nativeElement, 'height', this.uploadPassContent.nativeElement.offsetHeight + 'px');
    setTimeout(() => {
      this.newIdentityExpanded = true;
    }, 600);
  }

  onIdSelect(id: IdentificationType) {
    if (id.code !== this.selectedIdType?.code) {
      this.selectedIdType = id;
      this.identityDetailForm.reset();
      this.identityImage = null;
      this.identityImageObservable.next(this.getIdEvent());
      this.identityDetailForm.get('identificationTypeId').setValue(id?.id);
      if (!ID_CONSTANTS[id.code].isExpiryDateMandatory) {
        this.identityDetailForm.get('expiryDate').clearValidators();
      } else {
        this.identityDetailForm.get('expiryDate').setValidators(Validators.required);
      }
      this.identityDetailForm.get('expiryDate').updateValueAndValidity();
      this.newIdentity = true;
      this.identityDetailForm.enable();
    }
  }

  updateId() {
    this.selectedIdType = undefined;
    this.newIdentity = true;
  }

  private prepareAddressForm() {
    const addressDetail: UserProfileAddressDetails = this.userProfile?.addressDetails;
    return this.fb.group({
      address1: [(addressDetail && addressDetail.streetAddress1) || '', [
        Validators.required,
        Validators.pattern(ADDRESS_REGEXP),
        emptyWhiteSpaceValidator()
      ]],
      address2: [(addressDetail && addressDetail.streetAddress2) || '', [
        Validators.pattern(ADDRESS_REGEXP),
        emptyWhiteSpaceValidator()
      ]],
      country: [(addressDetail && addressDetail.countryId) || '', Validators.required],
      province: [(addressDetail && addressDetail.territoryId) || ''],
      city: [(addressDetail && addressDetail.city) || '', emptyWhiteSpaceValidator()],
      postcode: (addressDetail && addressDetail.postCode) || '',
    }, { validators: [postValidator] });
  }

  private prepareIdentityDetailForm() {
    const userIdentity = this.userProfile?.identityDetails;
    const nationalityId = PrepareSurveyFormService.getSavedDataWithOtherProperty('nationality', this.userProfile, this.nationalities);
    return this.fb.group({
      identityNo: [userIdentity && userIdentity.number || '', [Validators.required, Validators.pattern(IDENTITYNO_REGEXP),
      Validators.maxLength(20)]],
      countryOfNationality: [nationalityId, [Validators.required, emptyWhiteSpaceValidator()]],
      issuingAuthority: [
        (userIdentity && userIdentity.issuingAuthority) || '',
        [Validators.maxLength(50), emptyWhiteSpaceValidator()]
      ],
      expiryDate: [
        (
          userIdentity &&
          userIdentity.expiryDate &&
          DateTime.fromFormat(userIdentity.expiryDate || '', 'yyyy-MM-dd').toFormat('dd/MM/yyyy')
        ) || '',
        Validators.required,
      ],
      identificationTypeId: userIdentity && userIdentity.identificationTypeId
    }, { validators: ExpiryDateValidatorsHelper.expiryDateValidator() });
  }

  getPersonalInformationValidators(): ValidatorFn[] {
    return ([nameValidator, DobDateCheckerHelper.checkDate(), titleGenderValidator(this.personalGenderList)]);
  }

  private preparePersonalInformationForm() {
    this.getGender();
    const personalDetail: UserProfileResponse = this.userProfile;
    const user = new User();
    this.selectedCountryISO = getCountryCode(personalDetail?.mobileNumber ?? '');
    return this.fb.group({
      firstName: [(personalDetail && personalDetail.firstName) || ''
      ],
      lastName: [(personalDetail && personalDetail.lastName) || ''
      ],
      email: [(personalDetail && personalDetail.emailAddress) || '',
      { validators: [user.getValidatorFields('email')] }
      ],
      mobileNumber: [(personalDetail && personalDetail.mobileNumber) || '',
      { validators: [user.getValidatorFields('mobileNumber')] }
      ],
      birthDate: [
        (
          personalDetail &&
          personalDetail.dateOfBirth &&
          DateTime.fromFormat(personalDetail.dateOfBirth || '', 'yyyy-MM-dd').toFormat('dd/MM/yyyy')
        ) || '',
        { validators: [user.getValidatorFields('birthDate')] }
      ],
      title: [(personalDetail && personalDetail.title) || '',
      [Validators.required]
      ],
      gender: [(personalDetail && personalDetail.genderId) || '',
      [Validators.required]
      ],
      marketingCheckbox: personalDetail && personalDetail?.marketingDetails?.preparationContactPermission,
      communicationsCheckbox: personalDetail && personalDetail?.marketingDetails?.studyContactPermission,
    },
      { validators: this.getPersonalInformationValidators() });
  }

  private getGender() {
    this.apiService.getGender.subscribe(values => {
      if (Array.isArray(values)) {
        this.personalGenderList = [...values
          .filter(i => i.name.toLowerCase() !== 'other')
        ];
        this.setPersonalInfoFormValidator();
      }
    });
  }

  updateAddressDetails(event) {
    if (this.addressDetailsForm.valid) {
      this.updateProfile('addressDetail', event);
      this.addressDetailsForm.markAsPristine();
    }
  }

  updateSurvey($event) {
    if (this.surveyForm.valid) {
      this.mapperService.surveyForm = this.surveyForm;
      this.updateProfile('survey', $event);
      this.surveyForm.markAsPristine();
    }
  }

  updateIdentityDetails(event) {
    if (this.identityDetailForm.valid && this.identityImage) {
      this.updateProfile('passportdetail', event);
      this.identityDetailForm.markAsPristine();
    }
  }

  surveyChange(args: [{
    key: string,
    selectedModel: any
  }, string]) {
    try {
      if (Array.isArray(args) && args[0] && args[0].selectedModel && Object.keys(args[0].selectedModel).length) {
        const { selectedModel } = args[0];
        if (selectedModel.id === 'other') {
          this.surveyForm.get(args[1]).setValue(selectedModel);
        }
      }
    } catch (error) { /* empty */ }
  }

  updatePersonalDetails(event) {
    if (this.personalInformationForm.valid) {
      this.updateProfile('personaldetail', event);
      this.personalInformationForm.markAsPristine();
    }
  }

  updateProfile(section: ISections, SectionButton) {
    this.SetUpdateButtonStatus(SectionButton, 'loading');
    this.loadingService.increaseLoadingCounter();
    this.userProfileService.updateProfile(
      this.userProfile?.userProfileId,
      this.mapperService.generateUserProfileRequest(section))
      .pipe(first(), delay(3000)).subscribe(result => {
        this.loadingService.decreaseLoadingCounter();
        this.SetUpdateButtonStatus(SectionButton, 'success');
        this.store.dispatch(setUserFTime(DateTime.now()));
        this.store.dispatch(setUserDetails(result));
        this.mapperService.userProfileStore = result;
        if (section === 'passportdetail') {
          this.newIdentity = false;
          this.identityDetailForm.disable();
        }
        this.getAllDashboardApplications();
      }, err => {
        this.SetUpdateButtonStatus(SectionButton, 'fail');
        console.log(err);
      });
  }

  SetUpdateButtonStatus(section, status) {
    const btnSelected: MultiActionButtonComponent = section;
    switch (status) {
      case ('normal'): {
        btnSelected.loading = false;
        btnSelected.failed = false;
        btnSelected.successed = false;
        break;
      }
      case ('loading'): {
        btnSelected.loading = true;
        break;
      }
      case ('success'): {
        btnSelected.successed = true;
        btnSelected.loading = false;
        btnSelected.failed = false;
        setTimeout(() => {
          this.SetUpdateButtonStatus(section, 'normal');
        }, 5000);
        break;
      }
      case ('fail'): {
        btnSelected.successed = false;
        btnSelected.loading = false;
        btnSelected.failed = true;
        setTimeout(() => {
          this.SetUpdateButtonStatus(section, 'normal');
        }, 5000);
        break;
      }
    }
  }

  getNationalities() {
    this.subscription.add(this.apiService.GetNationality.subscribe(values => {
      if (Array.isArray(values)) {
        this.nationalities = [...values];
      }
    }));
  }
}


