// tslint:disable: variable-name
import { UntypedFormGroup } from '@angular/forms';
import { IdentificationType, UserProfileRequest, UserProfile, UserProfileTitle,
         UserProfileResponse,
         ApplicationUserProfilePatchRequest, ApplicationUserProfileUpdateRequest } from '@idp-education/ors-test-taker-bff-client-v1';
import { ISections } from './models';
import { Subject } from 'rxjs';
import { isNumber, isString } from 'lodash';
import { IModel } from '../../shared/models/components/dropdown';
import { DateTime } from 'luxon';
import { Is3Image } from 'pages/my-tests/update-application-details/update-application-details.component';
const { signup } = require('src/assets/appSettings.json');
export class MapperService {
  private idImage;

  private _surveyForm: UntypedFormGroup;
  public get surveyForm(): UntypedFormGroup {
    return this._surveyForm;
  }
  public set surveyForm(v: UntypedFormGroup) {
    this._surveyForm = v;
  }
  private _versionId: string;
  public get versionId(): string {
    return this._versionId;
  }
  public set versionId(v: string) {
    this._versionId = v;
  }
  private _userProfileId: string;
  public get userProfileId(): string {
    return this._userProfileId;
  }
  public set userProfileId(id: string) {
    this._userProfileId = id;
  }

  constructor(
    private userProfile: UserProfileResponse | UserProfile,
    private addressDetailsForm: UntypedFormGroup,
    private passportDetailForm: UntypedFormGroup,
    private _idImage: Subject<Is3Image>,
    private personalInformationForm: UntypedFormGroup,
    private personalNIdInformationForm: UntypedFormGroup,
    private IdentificationTypes: Subject<IdentificationType[]>
  ) {
    // FIXME: This may cause a memory leak
    this._idImage.subscribe(img => {
      this.idImage = img;
    });
  }
  public set userProfileStore(v: UserProfileResponse | UserProfile) {
    this.userProfile = v;
  }
  public generateUserProfileRequest(section: ISections): UserProfileRequest {
    const { ...newState } = this.userProfile;
    switch (section) {
      case 'addressDetail':
        return this.generateAddressDetail(newState);
      case 'passportdetail':
        return this.generatePassportDetail(newState);
      case 'personaldetail':
        return this.generatePersonalDetail(newState);
      case 'personalNIdDetail':
        return this.generatePersonalNIdDetail(newState);
      case 'survey':
        return this.generateSurvey(newState);
    }
  }
  public generateApplicationUserProfileRequest(section: ISections): ApplicationUserProfileUpdateRequest {
    const { ...userProfileState } = this.userProfile || {};
    const newState = {...userProfileState, version: this.versionId, applicationTaggedUserProfile: undefined,
      createdBy: undefined, createdOn: undefined, modifiedBy: undefined, modifiedOn: undefined};
    switch (section) {
      case 'addressDetail':
        return this.generateAddressDetail(newState);
      case 'passportdetail':
        return this.generatePassportDetail(newState);
      case 'personaldetail':
        return this.generatePersonalDetail(newState);
      case 'personalNIdDetail':
        return this.generatePersonalNIdDetail(newState);
      case 'survey':
        return this.generateSurvey(newState);
    }
  }

  public generateApplicationUserProfilePatchRequest(section: ISections): ApplicationUserProfilePatchRequest {
    const { ...applicationUserProfileStore } = this.userProfile;
    const address = this.userProfile?.addressDetails;
    const userIdentityDetails = this.userProfile?.identityDetails;
    const userMarketingDetails = this.userProfile?.marketingDetails;
    const patchRequest: ApplicationUserProfilePatchRequest = {
      addressDetails: {
        city: address?.city,
        countryId: address?.countryId,
        postCode: address?.postCode,
        streetAddress1: address?.streetAddress1,
        streetAddress2: address?.streetAddress2,
        streetAddress3: address?.streetAddress3,
        streetAddress4: address?.streetAddress4,
        territoryId: address?.territoryId,
      },
      id: this.userProfileId,
      identityDetails: {
        identificationTypeId: userIdentityDetails?.identificationTypeId,
        issuingAuthority: userIdentityDetails?.issuingAuthority,
      },
      marketingDetails: {
        countryApplyingToId: userMarketingDetails?.countryApplyingToId,
        countryApplyingToOther: userMarketingDetails?.countryApplyingToOther,
        currentlyStudyingEnglishAt: userMarketingDetails?.currentlyStudyingEnglishAt,
        educationLevelId: userMarketingDetails?.educationLevelId,
        occupationLevelId: userMarketingDetails?.occupationLevelId,
        occupationLevelOther: userMarketingDetails?.occupationLevelOther,
        occupationSectorId: userMarketingDetails?.occupationSectorId,
        occupationSectorOther: userMarketingDetails?.occupationSectorOther,
        preparationContactPermission: userMarketingDetails?.preparationContactPermission,
        studyContactPermission: userMarketingDetails?.studyContactPermission,
        testReasonId: userMarketingDetails?.testReasonId,
        testReasonOther: userMarketingDetails?.testReasonOther,
        yearsOfStudy: userMarketingDetails?.yearsOfStudy,
      },
      mobileNumber: applicationUserProfileStore?.mobileNumber,
      title: applicationUserProfileStore?.title,
      version: this.versionId,
    };
    const newState = { ...patchRequest };
    switch (section) {
      case 'addressDetail':
        return this.applicationGenerateAddressDetail(newState);
      case 'passportdetail':
        return this.applicationGeneratePassportDetail(newState);
      case 'personaldetail':
        return this.applicationGeneratePersonalDetail(newState);
      case 'personalNIdDetail':
        return this.applicationGeneratePersonalNIdDetail(newState);
      case 'survey':
        return this.generateSurvey(newState);
    }
  }

  private generateAddressDetail(state) {
    const userProfile = {
      ...state,
      addressDetails: {
        streetAddress1: this.addressDetailsForm.get('address1').value.trim(),
        streetAddress2: this.addressDetailsForm.get('address2').value.trim(),
        countryId: this.getIdFromIModel(this.addressDetailsForm.get('country').value),
        territoryId: this.getIdFromIModel(this.addressDetailsForm.get('province').value),
        city: this.addressDetailsForm.get('city').value.trim(),
        postCode: this.addressDetailsForm.get('postcode').value.trim()
      }
    };
    return {
      ...userProfile,
      addressDetails: userProfile.addressDetails.countryId ? {
        ...userProfile.addressDetails
      } : undefined
    };
  }

  private applicationGenerateAddressDetail(state) {
    const applicationUserProfile = {
      ...state,
      addressDetails: {
        city: this.addressDetailsForm.get('city').value,
        countryId: this.getIdFromIModel(this.addressDetailsForm.get('country').value),
        postCode: this.addressDetailsForm.get('postcode').value,
        streetAddress1: this.addressDetailsForm.get('address1').value,
        streetAddress2: this.addressDetailsForm.get('address2').value,
        territoryId: this.getIdFromIModel(this.addressDetailsForm.get('province').value),
      }
    };
    return {
      ...applicationUserProfile,
      addressDetails: applicationUserProfile.addressDetails.countryId ? {
        ...applicationUserProfile.addressDetails
      } : undefined
    };
  }

  private generatePassportDetail(state) {
    const dateExpire = this.passportDetailForm?.get('expiryDate')?.value;
    const result = {
      ...state,
      nationalityId: this.isEqualWithOther(this.passportDetailForm.get('countryOfNationality').value, false),
      nationalityOther: this.isEqualWithOther(this.passportDetailForm.get('countryOfNationality').value, true),
      identityDetails: {
        ...state?.identityDetails,
        issuingAuthority: this.passportDetailForm.get('issuingAuthority').value,
        number: this.passportDetailForm.get('identityNo').value,
        expiryDate: dateExpire ? DateTime.fromFormat(dateExpire || '', 'd/M/yyyy').toFormat('yyyy-MM-dd') : undefined,
        s3Url: this.idImage.primaryId?.s3Url,
        version: this.idImage.primaryId?.amzVersion,
        identificationTypeId: this.passportDetailForm.get('identificationTypeId').value,
      }
    };
    return result;
  }

  private applicationGeneratePassportDetail(state) {
    const result = {
      ...state,
      identityDetails: {
        ...state?.identityDetails,
        issuingAuthority: this.passportDetailForm.get('issuingAuthority').value,
        identificationTypeId: this.passportDetailForm.get('identificationTypeId').value,
      }
    };
    return result;
  }

  private getIdFromIModel(model: IModel<any> | string): string {
    if (!isString(model)) {
      return (model as IModel<any>)?.Id;
    } else if (model === '') {
      return undefined;
    }
    return model.toString();
  }
  private generatePersonalDetail(state) {
    const result = {
      ...state,
      firstName: this.personalInformationForm.get('firstName').value,
      lastName: this.personalInformationForm.get('lastName').value,
      emailAddress: this.personalInformationForm.get('email').value,
      mobileNumber: this.personalInformationForm.get('mobileNumber').value.e164Number,
      dateOfBirth: DateTime.fromFormat(this.personalInformationForm.get('birthDate').value || '', 'd/M/yyyy').toFormat('yyyy-MM-dd'),
      title: UserProfileTitle[this.getIdFromIModel(this.personalInformationForm.get('title').value)],
      marketingDetails: {
        ...state?.marketingDetails,
        preparationContactPermission: this.personalInformationForm.get('marketingCheckbox').value,
        studyContactPermission: this.personalInformationForm.get('communicationsCheckbox').value,
      },
      genderId: this.getIdFromIModel(this.personalInformationForm.get('gender').value),
    };
    return result;
  }

  private applicationGeneratePersonalDetail(state) {
    const result = {
      ...state,
      mobileNumber: this.personalInformationForm.get('mobileNumber').value.e164Number,
      title: UserProfileTitle[this.getIdFromIModel(this.personalInformationForm.get('title').value)],
      marketingDetails: {
        ...state?.marketingDetails,
        preparationContactPermission: this.personalInformationForm.get('marketingCheckbox').value,
        studyContactPermission: this.personalInformationForm.get('communicationsCheckbox').value,
      },
    };
    return result;
  }

  private isEqualWithOther(value: IModel<any> | string | number, isOtherAttribute: boolean): string {
    if (value && (value as IModel<any>)?.extendValue && (value as IModel<any>).extendValue?.isNewOption) {
      if (isOtherAttribute) {
        return (value as IModel<any>).label?.trim();
      } else {
        return (value as IModel<any>).extendValue.addNewOptionsId;
      }
    } else {
      if (isOtherAttribute) {
        return undefined;
      } else {
        return isString(value) || isNumber(value) ? `${value}` : (value as IModel<any>).Id;
      }
    }
  }
  private generateSurvey(state) {
    const temp = {
      ...state,
      languageId: this.isEqualWithOther(this.surveyForm.get('firstLanguage').value, false),
      languageOther: this.isEqualWithOther(this.surveyForm.get('firstLanguage').value, true),
      marketingDetails: {
        ...state?.marketingDetails,
        countryApplyingToId: this.isEqualWithOther(this.surveyForm.get('intendLocation').value, false),
        countryApplyingToOther: this.isEqualWithOther(this.surveyForm.get('intendLocation').value, true),
        educationLevelId: this.isEqualWithOther(this.surveyForm.get('educationLevel').value, false),
        occupationSectorId: this.isEqualWithOther(this.surveyForm.get('occupationSector').value, false),
        occupationSectorOther: this.isEqualWithOther(this.surveyForm.get('occupationSector').value, true),
        occupationLevelId: this.isEqualWithOther(this.surveyForm.get('occupationLevel').value, false),
        occupationLevelOther: this.isEqualWithOther(this.surveyForm.get('occupationLevel').value, true),
        yearsOfStudy: this.isEqualWithOther(this.surveyForm.get('yearsStudyingEnglish').value, false),
        testReasonOther: this.isEqualWithOther(this.surveyForm.get('reasonTest').value, true),
        testReasonId: this.isEqualWithOther(this.surveyForm.get('reasonTest').value, false),
        currentlyStudyingEnglishAt: this.surveyForm.get('locationStudyEnglish').value,
      }
    };
    return temp;
  }

  private applicationGeneratePersonalNIdDetail(state) {
    const identityType = this.personalNIdInformationForm.get('identityType').value;
    const identificationIDType = typeof identityType;
    const result = {
      ...state,
      mobileNumber: this.personalNIdInformationForm.get('mobileNumber').value.e164Number,
      title: UserProfileTitle[this.getIdFromIModel(this.personalNIdInformationForm.get('title').value)],
      identityDetails: {
        ...state?.identityDetails,
        issuingAuthority: this.personalNIdInformationForm.get('issuingAuthority').value,
        identificationTypeId: (identificationIDType === 'string') ? identityType : identityType?.Id,
      },
      marketingDetails: {
        ...state?.marketingDetails,
        preparationContactPermission: this.personalNIdInformationForm.get('marketingCheckbox').value,
        studyContactPermission: this.personalNIdInformationForm.get('communicationsCheckbox').value,
      }
    };
    return result;
  }

  private generatePersonalNIdDetail(state) {
    const dateExpire = this.personalNIdInformationForm?.get('expiryDate')?.value;
    const identityType = this.personalNIdInformationForm.get('identityType').value;
    const identificationIDType = typeof identityType;
    const result = {
      ...state,
      firstName: this.personalNIdInformationForm.get('firstName').value,
      lastName: this.personalNIdInformationForm.get('lastName').value,
      emailAddress: this.personalNIdInformationForm.get('email').value,
      mobileNumber: this.personalNIdInformationForm.get('mobileNumber').value.e164Number,
      dateOfBirth: DateTime.fromFormat(this.personalNIdInformationForm.get('birthDate').value || '', 'd/M/yyyy').toFormat('yyyy-MM-dd'),
      title: UserProfileTitle[this.getIdFromIModel(this.personalNIdInformationForm.get('title').value)],
      identityDetails: {
        ...state?.identityDetails,
        issuingAuthority: this.personalNIdInformationForm.get('issuingAuthority').value,
        number: this.personalNIdInformationForm.get('identityNo').value,
        expiryDate: dateExpire ? DateTime.fromFormat(dateExpire || '', 'd/M/yyyy').toFormat('yyyy-MM-dd') : undefined,
        s3Url: this.idImage ? this.idImage?.primaryId?.s3Url : state?.identityDetails?.s3Url,
        version: this.idImage ? this.idImage.primaryId?.amzVersion : state?.identityDetails?.version ,
        identificationTypeId: (identificationIDType === 'string') ? identityType : identityType?.Id,
        additionalImages: this.idImage?.secondaryId ? [{
          s3Url: this.idImage?.secondaryId?.s3Url,
          version: this.idImage?.secondaryId?.amzVersion 
        }] : [{
          s3Url: signup.secondaryS3IdImageUrl,
          version: signup.secondaryIdVersion 
        }]
      },
      marketingDetails: {
        ...state?.marketingDetails,
        preparationContactPermission: this.personalNIdInformationForm.get('marketingCheckbox').value,
        studyContactPermission: this.personalNIdInformationForm.get('communicationsCheckbox').value,
      },
      genderId: this.getIdFromIModel(this.personalNIdInformationForm.get('gender').value),
      nationalityId: this.isEqualWithOther(this.personalNIdInformationForm.get('countryOfNationality').value, false),
      nationalityOther: this.isEqualWithOther(this.personalNIdInformationForm.get('countryOfNationality').value, true)
    };
    return result;
  }
}
