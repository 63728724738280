import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardItem } from '@idp-education/ors-test-taker-bff-client-v1';
import { Observable, Subscription } from 'rxjs';
import { IList, IResultType } from 'src/app/shared/models/components/past-test';
import { Store } from '@ngrx/store';
import { SharedStateService } from 'src/app/pages/my-tests/shared-state.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IDashboardItemMeta } from 'shared/components/upcoming-test/upcoming-test.component';
import { isIOCProduct } from 'shared/utils/is-ioc-product';
import { generateUtmUrl } from 'shared/utils/utm-params-utils';
const { featureFlags, site } = require('src/assets/appSettings.json');
export type IScore = 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5 | 5 | 5.5 | 6 | 6.5 | 7 | 7.5 | 8 | 8.5 | 9;
export enum CommentType {
  overallComments = 'overallComments',
  listeningComments = 'listeningComments',
  readingComments = 'readingComments',
  writingComments = 'writingComments',
  speakingComments = 'speakingComments',
}
type IComment = { score?: IScore, text: string, improve?: string };
export type IComments = {
  [key in CommentType]: { [score in IScore]: IComment }
};
type IState = 'Overall' | 'Listening' | 'Reading' | 'Writing' | 'Speaking';
enum StateToComment {
  'Overall' = 'overallComments',
  'Listening' = 'listeningComments',
  'Reading' = 'readingComments',
  'Writing' = 'writingComments',
  'Speaking' = 'speakingComments'
}
enum StateToDashboardItemResult {
  'Overall' = 'overallScore',
  'Listening' = 'listeningScore',
  'Reading' = 'readingScore',
  'Writing' = 'writingScore',
  'Speaking' = 'speakingScore',
}
const Comments: IComments = require('./comments.json');
@Component({
  selector: 'app-past-test-child',
  templateUrl: './past-test-child.component.html',
  styleUrls: ['./past-test-child.component.scss']
})
export class PastTestChildComponent implements OnInit {
  get comment(): IComment {
    return this.commentObj();
  }

  sub: Subscription;
  @Input() showOptions = false;
  @Input() title: string;
  @Input() test: DashboardItem;
  @Input() eTRFUrl$: Observable<string>;
  @Input() meta: IDashboardItemMeta = {};
  @Output() onClickOptions: EventEmitter<any> = new EventEmitter();
  @Output() onRequestRemarkClick = new EventEmitter<{ test: DashboardItem; excemption: any }>();
  @Output() onViewRemarkClick: EventEmitter<DashboardItem> = new EventEmitter();
  @Output() onssrSearchClick: EventEmitter<{ test: DashboardItem, component: string }> = new EventEmitter();
  activeState: IState = 'Overall';
  score = '';
  showMore = false;
  public featureFlags: any = featureFlags;
  showScoreDescription = false;
  enableSSR: boolean;
  enableDownloadEtrf: boolean;
  ssrSelected: boolean;
  ssrComponent: string;
  comments: IComments = Comments;
  lists: IList[] = [];
  list: IList[] = [
    {
      key: 'CHANGE_BOOKING',
      image: '../../../../../assets/images/Icon-ionic-ios-calendar.svg',
      label: 'Change Booking'
    }, {
      key: 'NOTIFY_INSTITUTION',
      image: '../../../../../assets/images/Icon-ionic-ios-mail.svg',
      label: 'Notify Institution'
    }, {
      key: 'REFUND_REQUEST',
      image: '../../../../../assets/images/Icon-ionic-ios-wallet.svg',
      label: 'Refund Request'
    }, {
      key: 'VIEW_RECEIPT',
      image: '../../../../../assets/images/Icon-ionic-ios-wallet.svg',
      label: 'View Receipt'
    }, {
      key: 'VIEW_TERMS_AND_CONDITIONS',
      image: '../../../../../assets/images/Icon-ionic-ios-document.svg',
      label: 'Terms & Conditions'
    }, {
      key: 'UPDATE_DETAILS',
      image: '../../../../../assets/images/Icon-feather-edit-blue.svg',
      label: 'Update Details'
    }, {
      key: 'TRANSFER_BOOKINGS',
      image: '../../../../../assets/images/Icon-ionic-ios-calendar.svg',
      label: 'Change Booking'
    }
  ];
  osrCheckboxForm = new UntypedFormGroup({
    osrCheckbox: new UntypedFormControl('', Validators.required),
  });
  excemption = { listening: false, reading: false, writing: false, speaking: false};
  private commentObj(): IComment {
    const currentScore = this.test.results[StateToDashboardItemResult[this.activeState]];
    return this.comments[StateToComment[this.activeState]][currentScore];
  }
  constructor(private store: Store<{ globalStore }>, private sharedState: SharedStateService) {
    this.store.select(appState => appState.globalStore).subscribe(x => {
      this.enableSSR = x.enableSSR;
      this.enableDownloadEtrf = x.enableDownloadEtrf;
    });
  }
  ngOnInit(): void {
    this.test?.actions?.forEach(action => {
      this.list.forEach(listItem => {
        if (action.actionCode === listItem.key) {
          this.lists.push(listItem);
        }
      });
    });
    this.getExcemptionDetails();
  }

  getExcemptionDetails() {
    if (this.test?.accessArrangement) {
      this.test.accessArrangement.forEach(data => {
        if (data.excemption) {
          if (data.component === 'L') {
            this.excemption.listening = true;
          } else if (data.component === 'R') {
            this.excemption.reading = true;
          } else if (data.component === 'W') {
            this.excemption.writing = true;
          } else if (data.component === 'S') {
            this.excemption.speaking = true;
          }
        }
      })
    }
  }

  itemClick(state: IState) {
    this.activeState = state;
  }
  getTestStartDate(): string {
    return this.sharedState.getTestStartDate(this.test);
  }
  bookTestClick(): void {
    window.open(generateUtmUrl(site.bxsearchURL), '_blank');
  }
  getStatus() {
    const testStatus = this?.test?.status;
    switch (testStatus) {
      case 'CONFIRM_TIPS_EOR_PAYMENT':
      case 'RESULTS_AVAILABLE':
        this.showScoreDescription = true;
        return 'Results available';
      case 'EOR_IN_PROGRESS':
        this.showScoreDescription = true;
        return 'Re-mark in progress';
      case 'EOR_COMPLETED':
        this.showScoreDescription = true;
        return 'Results available - re-mark completed';
      case 'EOR_OFFLINE_PAYMENT_IN_PROGRESS':
        this.showScoreDescription = true;
        return 'Remark Request Offline Payment in Progress';
      default:
        this.showScoreDescription = false;
        return testStatus?.replace(/[_]/gi, ' ').toLowerCase();
    }
  }
  isAwaitingResult() {
    return this?.test?.status === 'AWAITING_RESULT';
  }
  getResult(type: IResultType) {
    if (!this?.test?.results) {
      return '_';
    }
    try {
      return (+this?.test?.results[type]).toFixed(1);
    } catch (error) {
      return this?.test?.results[type];
    }
  }
  downloadURL() {
    if (this.eTRFUrl$) {
      this.eTRFUrl$.subscribe(url => {
        if (url) {
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.click();
        }
      });
    }
  }
  onRetakeClick(ssrComponent: string) {
    this.ssrSelected = true;
    this.ssrComponent = ssrComponent;
    this.onssrSearchClick.emit({ test: this.test, component: this.ssrComponent });
  }
  osrAllowedTimeZones(): boolean {
    const allowedTimeZone = [
      'asia/ho_chi_minh',
      'asia/singapore',
      'europe/madrid',
      'europe/lisbon',
      'europe/zurich',
      'europe/amsterdam',
      'europe/rome',
      'europe/warsaw',
      'europe/belgrade',
      'europe/podgorica',
      'europe/chisinau',
      'africa/nairobi',
      'asia/colombo',
      'asia/jakarta',
      'asia/makassar',
      'asia/qatar',
      'asia/tehran',
      'europe/istanbul',
      'asia/vientiane',
      'america/denver',
      'asia/dubai',
      'america/new_york',
      'indian/mauritius',
      'europe/nicosia',
      'europe/athens',
      'africa/cairo',
      'africa/lagos',
      'asia/karachi',
      'asia/katmandu',
      'asia/kuwait',
      'asia/riyadh',
      'asia/samarkand',
      'asia/yangon',
      'europe/bucharest',
      'europe/dublin',
      'europe/paris',
      'america/vancouver',
      'asia/muscat',
      'australia',
      'pacific/port_moresby',
      'asia/phnom_penh',
      'asia/manila',
      'canada/atlantic',
      'canada/central',
      'canada/eastern',
      'canada/mountain',
      'canada/newfoundland',
      'canada/pacific',
      'canada/saskatchewan',
      'canada/yukon',
      'asia/kuala_lumpur',
      'asia/kuching',
      'asia/hong_kong',
      'pacific/auckland',
      'america/araguaina',
      'america/argentina/buenos_aires',
      'america/argentina/catamarca',
      'america/argentina/comodrivadavia',
      'america/argentina/cordoba',
      'america/argentina/jujuy',
      'america/argentina/la_rioja',
      'america/argentina/mendoza',
      'america/argentina/rio_gallegos',
      'america/argentina/salta',
      'america/argentina/san_juan',
      'america/argentina/san_luis',
      'america/argentina/tucuman',
      'america/argentina/ushuaia',
      'america/bahia',
      'america/bahia_banderas',
      'america/belem',
      'america/boa_vista',
      'america/bogota',
      'america/campo_grande',
      'america/cancun',
      'america/chihuahua',
      'america/cuiaba',
      'america/eirunepe',
      'america/fortaleza',
      'america/guayaquil',
      'america/hermosillo',
      'america/lima',
      'america/maceio',
      'america/manaus',
      'america/matamoros',
      'america/mazatlan',
      'america/merida',
      'america/mexico_city',
      'america/monterrey',
      'america/montevideo',
      'america/noronha',
      'america/ojinaga',
      'america/porto_velho',
      'america/recife',
      'america/rio_branco',
      'america/santa_isabel',
      'america/santarem',
      'america/santiago',
      'america/sao_paulo',
      'america/tijuana',
      'asia/almaty',
      'asia/amman',
      'asia/aqtau',
      'asia/aqtobe',
      'asia/bahrain',
      'asia/baku',
      'asia/bangkok',
      'asia/beirut',
      'asia/dhaka',
      'asia/seoul',
      'asia/taipei',
      'asia/tokyo',
      'asia/yerevan',
    ];
    const isOSRAllowed = (str, arr) => arr.some(item => str?.includes(item));
    return isOSRAllowed(this.test?.testLocationTimeZone?.toLowerCase(), allowedTimeZone);
  }
  showSSROnAppStatus(): boolean {
    return this?.test?.status === 'RESULTS_AVAILABLE' || this?.test?.status === 'EOR_COMPLETED';
  }
  showRequestRemark(): boolean {
    return !!this.test?.actions.find(action => action.actionCode === 'RAISE_EOR' || action.actionCode === 'REVIEW_EOR') &&
      !this.isPendingOfflineEOR();
  }
  showViewRemark(): boolean {
    return !!this.test?.actions.find(action => action.actionCode === 'VIEW_EOR');
  }
  showDownloadeTRF(): boolean {
    if (this.test?.productName.includes('IELTS Online')) {
      return !!this.test?.actions.find(action => action.actionCode === 'DOWNLOAD_ETRF');
    } else {
      return !!this.test?.actions.find(action => action.actionCode === 'DOWNLOAD_ETRF') && this.enableDownloadEtrf;
    }
  }
  showOSR(): boolean {
    return isIOCProduct(this.test?.productName) && !!(this.test?.actions.find(action => action.actionCode === 'RETAKE_SINGLE_SKILL'));
  }
  isPendingOfflineEOR(): boolean {
    return this.test?.status === 'EOR_OFFLINE_PAYMENT_IN_PROGRESS';
  }
  onEORCompleteBooking(meta: IDashboardItemMeta) {
    meta?.callToAction();
    this.onRequestRemarkClick.emit({test: this.test, excemption: this.excemption});
  }
}
