import {
  Component,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges, OnDestroy
} from '@angular/core';
import {
  ApplicationPaymentStatus,
  ApplicationService,
  DashboardItemStatus,
  DashboardItem
} from '@idp-education/ors-test-taker-bff-client-v1';
import { ApplicationService as ApplicationServiceV2 } from '@idp-education/ors-test-taker-bff-client-v2';
import { from, Observable, of, Subscription, timer } from 'rxjs';
import { ApplicationsService } from './../../services/applications.service';
import { isObject } from 'shared/utils';
import { concatMap, finalize, tap, toArray } from 'rxjs/operators';
import { setFromTips } from 'pages/payment/store/payment.actions';
import { FeeType, TipsService } from 'shared/services/tips.service';
import { Store } from '@ngrx/store';
import { IDashboardItemMeta } from 'shared/components/upcoming-test/upcoming-test.component';

@Component({
  selector: 'app-past-test',
  templateUrl: './past-test.component.html',
  styleUrls: ['./past-test.component.scss']
})
export class PastTestComponent implements OnChanges, OnDestroy {
  @Input() title: string;
  @Input() tests: DashboardItem[] = [];
  @Input() showOptions = false;
  @Output() onClickOption: EventEmitter<any> = new EventEmitter();
  @Output() onRequestRemarkClick = new EventEmitter<{ test: DashboardItem; excemption: any }>();
  @Output() onViewRemarkClick: EventEmitter<DashboardItem> = new EventEmitter();
  @Output() onssrSearchClick: EventEmitter<{ test: DashboardItem, component: string }> = new EventEmitter();
  unpaidApplicationSubscription: Subscription = new Subscription();
  itemMeta: IDashboardItemMeta = {};
  enableDashboardV2: boolean;

  constructor(
    private cd: ChangeDetectorRef,
    private applicationService: ApplicationsService,
    private tipsService: TipsService,
    private store: Store<{ applicationsStore, globalStore }>,
    private bffAppService: ApplicationService,
    private applicationServiceV2: ApplicationServiceV2
  ) {
    this.unpaidApplicationSubscription = this.store.select(appState => appState?.globalStore?.enableDashboardV2)
                                      .subscribe(enableDashboardV2 => {
      if (enableDashboardV2) {
          this.enableDashboardV2 = enableDashboardV2;
          localStorage.setItem('enableDashboardV2', JSON.stringify(enableDashboardV2));
      }
  });
  }

  get applications() {
    return this.tests;
  }

  getURL(appId: string): Observable<string> {
    return this.applicationService.getPDFUrl(appId);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tests.currentValue) {
      this.verifyEORTipsPayment();
    }
  }

  private async verifyEORTipsPayment() {
    this.bffAppService.defaultHeaders = this.bffAppService.defaultHeaders.set('loading', 'off');
    this.bffAppService.defaultHeaders = this.bffAppService.defaultHeaders.set('suppressError', 'on');
    this.unpaidApplicationSubscription.add(from(this.tests.filter(dashboardItem => (
      dashboardItem.status === DashboardItemStatus.CONFIRMTIPSEORPAYMENT
    ))).pipe(concatMap(dashboardItem => {
      this.itemMeta[dashboardItem.applicationId] = {
        isLoading: true,
        productName: dashboardItem.productName,
        dashboardItem
      };
      return this.tipsService.verifyTipsPayment(dashboardItem.applicationId, true, false);
    }), concatMap(verificationResult => {
      const itemMeta = this.itemMeta[verificationResult.application.id];
      if (itemMeta) {
        if (verificationResult.applicationPayment.status === ApplicationPaymentStatus.PAID) {
          return this.refreshDashboardStatus(verificationResult.application.id);
        } else {
          itemMeta.isLoading = false;
          itemMeta.callToAction = () => {
            this.store.dispatch(setFromTips({ fromTips: true }));
            this.tipsService.onCancel(
              FeeType.EOR,
              itemMeta.productName,
              verificationResult.application,
              verificationResult.applicationPayment
            );
          };
        }
      }
      return of(null);
    }), toArray(), finalize(() => {
      Object.keys(this.itemMeta).forEach(key => {
        const meta = this.itemMeta[key];
        if (isObject(meta) && meta.isLoading === true) {
          delete meta.isLoading;
        }
      });
      this.bffAppService.defaultHeaders = this.bffAppService.defaultHeaders.delete('loading');
      this.bffAppService.defaultHeaders = this.bffAppService.defaultHeaders.delete('suppressError');
    })).subscribe());
  }

  refreshDashboardStatus(applicationId: string) {
    const getDashBoardItem = localStorage.getItem('enableDashboardV2');
    this.enableDashboardV2 = (getDashBoardItem === 'true');
    return timer(2000).pipe(
      concatMap(() =>
      this.enableDashboardV2 ?
      this.applicationServiceV2.getApplicationsDashboardV2() :
      this.bffAppService.getApplicationsDashboard()),
      tap((response: any) => {
        const dashboardItem: DashboardItem = response.pastTests.find(item => item.applicationId === applicationId);
        if (dashboardItem) {
          const item = this.itemMeta[applicationId].dashboardItem;
          if (item) {
            item.actions = dashboardItem.actions;
            // tactical solution for slow back end process updating dashboard status for paid TIPS EOR payment
            // TODO: remove when backend perform better
            item.status =
              dashboardItem.status === DashboardItemStatus.RESULTSAVAILABLE ?
                DashboardItemStatus.EORINPROGRESS :
                dashboardItem.status;
          }
        }
      }
    ));
  }

  ngOnDestroy(): void {
    this.unpaidApplicationSubscription?.unsubscribe();
    if (this.bffAppService.defaultHeaders) {
      this.bffAppService.defaultHeaders = this.bffAppService.defaultHeaders.delete('loading');
      this.bffAppService.defaultHeaders = this.bffAppService.defaultHeaders.delete('suppressError');
    }
  }
}
