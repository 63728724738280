import { ToastrService } from 'ngx-toastr';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Storage } from 'aws-amplify';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { Subject } from 'rxjs';
import {
  convertUrlToFile,
  detectWebcam, fetchExtension,
  getPhoto, getVersion,
  validateFile, compressFile, ImageTransformType
} from './image-utils';
import { imageTransformer } from './image-transformer';
import { environment } from '../../../../../environments/environment';
import { take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'shared/services/auth.service';
import { WebcamModalComponent } from 'shared/components/webcam-modal/webcam-modal.component';
import { CropModalComponent } from 'shared/components/crop-modal/crop-modal.component';
import { CameraFunctionsService } from 'shared/services/camera-functions.service';
import { IidImageSave } from 'pages/my-tests/update-application-details/update-application-details.component';
const { featureFlags } = require('src/assets/appSettings.json');
export type DocumentPhotoUpdateEvent = { newDocUploaded?: boolean; file?: File, url?: string };

@Component({
  selector: 'app-application-document-upload-form',
  templateUrl: './application-document-upload-form.component.html',
  styleUrls: [
    './application-document-upload-form.component.scss',
    '../identity-document-upload-form/identity-document-upload-form.component.scss'
  ],
})
export class ApplicationDocumentUploadFormComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input('imageUrl') inputImageUrl = null;
  @Input() editableImage = false;
  @Input() deletableImage = true;
  @Input() idNumber = 'id1';
  @Input() title? = '';
  @Input() cancelChanges$?: Subject<void>;
  @Input() poolImageUrl?: string;
  @Input() saveTheChanges$?: Subject<IidImageSave>;

  @Input() imageTransformed: boolean;
  @Output() imageTransformedChange = new EventEmitter<boolean>();
  @Input() resetTransforms$?: Subject<string>;
  @Output() transformChangesReset$ = new Subject<string | void>();

  @Output() uploaded = new EventEmitter<object>();
  @Output() deleted = new EventEmitter();
  @Input() resetCacheUpload$?: Subject<void>;
  @Output() documentPhotoUpdated = new EventEmitter<DocumentPhotoUpdateEvent>();

  @ViewChild('inputfile') inputFile: ElementRef;
  @ViewChild('uploadBox') uploadBox: ElementRef;
  @ViewChild('deletePhotoModal') deletePhotoModal: ConfirmModalComponent;

  completeUpload = false;
  hasWebcam = false;
  photo: string;
  errorCode: string;
  MAX_FILE_SIZE = 'MAX_FILE_SIZE';
  percent = 0;
  uploadObj: Promise<object> = null;
  hasUploadError = false;
  uploadError = '';
  visibleButtons = true;
  imageUrl = '';
  editImageState:
    | 'notStarted'
    | 'inEditRotateLeft'
    | 'inEditRotateRight'
    | 'inEditFlip' = 'notStarted';
  cachedTransformedImage: { file?: File, url?: string, poolUrl?: string };
  inputImageUrlWasNull = false;
  // To flag if camera modal opened for editing existing photo
  cameraOpenedForUpdate = false;

  public file: File;
  public featureFlags: any = featureFlags;
  public cachedPhotoBeforeReplacement?: { file?: File, url?: string, poolUrl?: string };

  private readonly pdfIcon = 'src/assets/images/pdfIcon.svg';
  private userIdentityPoolId: string;
  private maxFileLength = 5 * 1024 * 1024;

  destroyed$ = new Subject();

  constructor(
    public modalService: NgbModal,
    private loading: LoadingService,
    private authenticationService: AuthService,
    private toastr: ToastrService,
    private cameraService: CameraFunctionsService,
  ) { }

  ngOnInit(): void {
    this.loading.increaseLoadingCounter();
    this.authenticationService.fetchCurrentUserInfo().then((cred) => {
      this.userIdentityPoolId = cred.id;
      this.loading.resetLoadingCounter();
    });
    this.listenToCancelChanges();
    this.listenToSaveChanges();
    this.listenToResetCacheUpload();
    this.listenToResetTransformation();
  }

  ngAfterViewInit(): void {
    this.listenToUploadInputChange();
    this.setHasWebcam();
  }

  private listenToResetTransformation(): void {
    if (this.resetTransforms$) {
      this.resetTransforms$.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
        if (val === 'delete') {
          this.setCacheOriginalPhoto();
        }
        else {
          this.onResetImageTransformations();
        }
      });
    }
  }

  private listenToCancelChanges(): void {
    if (this.cancelChanges$) {
      this.cancelChanges$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        if (this.inputImageUrlWasNull) {
          this.file = null;
          this.imageUrl = null;
          this.inputImageUrl = null;
          this.visibleButtons = true;
          this.resetBackground('white');
          this.completeUpload = false;
          this.clearTheCachedTransform();
        } else {
          if (this.cachedTransformedImage) {
            this.onResetImageTransformations();
          }
          if (this.cachedPhotoBeforeReplacement) {
            this.resetToOriginalPhoto();
          }
        }
      });
    }
  }

  private listenToSaveChanges(): void {
    if (!this.saveTheChanges$) {
      return;
    }
    this.saveTheChanges$.pipe(takeUntil(this.destroyed$)).subscribe(async (fileval) => {
      const updatedFile = fileval.updatedFile?.file || this.file;
      if (this.prepareUpload(updatedFile)) {
        this.resetBackground();
        const compressedFile = await compressFile(this.file, this.maxFileLength);
        const imageNo = fileval?.imageNo ? fileval.imageNo.slice(2) : '1';
        await this.uploadFile(compressedFile, imageNo);
      }
      this.resetCacheUpload();
    });
  }

  private listenToResetCacheUpload(): void {
    if (this.resetCacheUpload$) {
      this.resetCacheUpload$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.inputImageUrlWasNull = true;
      })
    }
  }

  private resetCacheUpload() {
    if (this.cachedTransformedImage) {
      this.clearTheCachedTransform();
    }
    if (this.cachedPhotoBeforeReplacement) {
      this.cachedPhotoBeforeReplacement = null;
    }
    if (this.inputImageUrlWasNull) {
      this.inputImageUrlWasNull = false;
    }
    if (this.cameraOpenedForUpdate) {
      this.cameraOpenedForUpdate = false;
    }
  }

  private listenToUploadInputChange(): void {
    if (this.inputFile) {
      this.inputFile.nativeElement.addEventListener(
        'change',
        this.onInputFileChange.bind(this)
      );
    }
  }

  private async setHasWebcam(): Promise<void> {
    this.hasWebcam = await detectWebcam();
  }

  async getIdentityPoolId() {
    return await this.authenticationService.fetchCurrentUserInfo();
  }

  async ngOnChanges(changes: SimpleChanges) {
    // Only proceed if the inputImageUrl has changed
    if (changes?.inputImageUrl && changes?.inputImageUrl?.currentValue !== null) {
      this.visibleButtons = false;
      this.hasUploadError = false;
      this.completeUpload = true;

      try {
        // Fetch the photo and update imageUrl
        this.imageUrl = await getPhoto(changes.inputImageUrl.currentValue);

        // Convert URL to file if needed, using a more efficient approach
        if (!this.file) {
          this.file = await convertUrlToFile(this.imageUrl);
        }

        this.resetBackground();
        this.setBackground(this.imageUrl);

      } catch (error) {
        // Handle potential errors during photo retrieval or conversion
        this.hasUploadError = true;
        this.errorCode = error.message;
      }
    } else if (changes?.inputImageUrl?.firstChange) {
      // Reset state for no inputImageUrl
      this.completeUpload = false;
      this.visibleButtons = true;
      this.hasUploadError = false;
      this.errorCode = '';
      this.photo = null;

      if (changes?.inputImageUrl?.currentValue === null) {
        this.inputImageUrlWasNull = true;
      }

      // Clear file input and reset background directly
      if (this.inputFile) {
        this.inputFile.nativeElement.value = '';
      }
      this.resetBackground('white');
    }
  }

  private hideUploadOptions(): void {
    this.visibleButtons = false;
    this.hasUploadError = false;
    this.completeUpload = true;
  }

  async onInputFileChange(e: Event) {
    this.cacheTheOriginalPhotoOnEdit();
    this.clearTheCachedTransform();

    const uploadedFile = (e.target as HTMLInputElement).files[0];
    this.setCanvasBackground({
      file: uploadedFile,
      url: URL.createObjectURL(uploadedFile)
    });

    this.hideUploadOptions();

    this.documentPhotoUpdated.emit({ newDocUploaded: true, file: this.file, url: this.photo });
  }

  private cacheTheOriginalPhotoOnEdit(): void {
    if (this.inputImageUrl !== null && this.file && !this.cachedPhotoBeforeReplacement) {
      // The action was edit and we should cache the file for case when user cancel the changes
      if (this.cachedTransformedImage) {
        // If user first started with transformation, we need to save the original version of it
        this.cachedPhotoBeforeReplacement = {
          file: this.cachedTransformedImage.file,
          url: this.cachedTransformedImage.url
        };
      } else {
        this.cachedPhotoBeforeReplacement = {
          file: this.file,
          url: this.imageUrl
        };
      }
    }
  }

  setCacheOriginalPhoto(): void {
    this.cacheTheOriginalPhotoOnEdit();
    this.clearTheCachedTransform();
  }

  setFileName(fileName, fileIdNo) {
    const fileExtension = fileName.split('.');
    if (fileExtension.length === 1) {
      return `${fileName}_${fileIdNo}`
    }
    const fileBaseName = fileExtension.slice(0, -1).join('.');
    return `${fileBaseName}_${fileIdNo}.${fileExtension[fileExtension.length - 1]}`;
  }

  async uploadFile(file: File, fileIdNo: string) {
    try {
      this.loading.increaseLoadingCounter();

      if (!file) {
        throw new Error('No file provided');
      }

      this.file = file;
      this.visibleButtons = false;
      this.hasUploadError = false;

      const fileName = this.setFileName(file.name, fileIdNo);
      const uploadStartTime = performance.now(); // More precise timing

      this.uploadObj = Storage.put(fileName, file, {
        progressCallback: (value: any) => {
          this.onUploadProgress(value);
        },
      });

      // @ts-ignore
      const { key } = await this.uploadObj;
      const getAmzVer = await getVersion(key);

      const userIdentityPoolId = this.userIdentityPoolId ?? (await this.getIdentityPoolId())?.id;

      if (!userIdentityPoolId) {
        throw new Error('Failed to retrieve user identity pool ID');
      }

      const imageUrl = await getPhoto(key);

      this.uploaded.emit({
        URL: `/${userIdentityPoolId}/${fileName}`,
        amzVersion: getAmzVer,
        imageId: fileIdNo
      });
      this.setBackground(imageUrl);
      this.completeUpload = true;

      const uploadEndTime = performance.now();
      if (!environment.production) {
        console.log(`Upload time: ${uploadEndTime - uploadStartTime} milliseconds`);
      }
    } catch (error) {
      this.hasUploadError = true;
      if (error.message.toLowerCase().includes('cancel')) {
        // TODO: Handle user-cancelled upload
      } else {
        this.toastr.error('Upload failed: ' + error.message);
      }
      this.loading.decreaseLoadingCounter();
    } finally {
      this.loading.resetLoadingCounter();
      this.loading.decreaseLoadingCounter();
    }
  }

  private setBackground(url: string) {
    if (url) {
      const style = this.uploadBox.nativeElement.style;
      style.background = '#fff';
      style.backgroundImage = `url('${fetchExtension(url).toLowerCase() !== 'pdf' ? url : this.pdfIcon
        }')`;
      style.backgroundRepeat = 'no-repeat';
      style.backgroundPosition = 'center';
      style.backgroundSize = 'contain';
      if (fetchExtension(url).toLowerCase() === 'pdf') {
        style.paddingTop = '5px';
        style.paddingBottom = '5px';
        style.backgroundOrigin = 'content-box';
      } else {
        style.paddingTop = '0';
        style.paddingBottom = '0';
        style.backgroundOrigin = 'border-box';
      }
    }
  }

  resetUpload() {
    this.visibleButtons = true;
    this.hasUploadError = false;
    this.errorCode = '';
    this.uploadBox.nativeElement.style.backgroundSize = '0%';
    (this.inputFile.nativeElement as HTMLInputElement).value = '';
  }

  tryAgainUpload() {
    this.visibleButtons = true;
    setTimeout(() => {
      this.cameraService.tryAgainUpload(
        this.uploadError,
        this.resetBackground.bind(this),
        this.inputFile?.nativeElement as HTMLInputElement,
        this.file,
        this.prepareUpload.bind(this),
        this.uploadFile.bind(this)
      );
    }, 1);
  }

  onUploadProgress(progress) {
    const percent = Math.round((progress.loaded / progress.total) * 100);
    this.uploadBox.nativeElement.style.backgroundSize = `${percent}%`;
    this.percent = percent;
  }

  abortUpload() {
    Storage.cancel(this.uploadObj);
    this.resetUpload();
  }

  async onWebcamPhotoTaken(pic: any): Promise<void> {
    this.cacheTheOriginalPhotoOnEdit();

    const fetchRes = await fetch(pic);
    const blob = await fetchRes.blob();
    const file = new File([blob], `${+new Date()}.jpg`, blob);
    this.setCanvasBackground({
      file: new File([file], 'File name', { type: 'image/png' }),
      url: URL.createObjectURL(blob)
    });

    this.clearTheCachedTransform();
    this.hideUploadOptions();
    this.documentPhotoUpdated.emit({ newDocUploaded: true });
  }

  onUploadClick() {
    this.inputFile.nativeElement.click();
  }

  deleteUpload() {
    this.deletePhotoModal.open().result.then((result: 'accept' | 'reject') => {
      if (result === 'reject') {
        return;
      }
      if (this.inputFile) {
        this.inputFile.nativeElement.value = '';
      }
      this.deleted.emit();
      this.resetBackground();
      this.photo = null;
      this.imageUrl = '';
      this.completeUpload = false;
      this.visibleButtons = true;
      this.hasUploadError = false;
    });
  }

  editIdDocumentImage(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'upload-type-modal',
    });
  }

  private resetBackground(background = 'linear-gradient(136deg, rgba(0,53,117,1) 0%, rgba(189,96,165,1) 100%) !important') {
    this.percent = 0;
    if (this.uploadBox) {
      this.uploadBox.nativeElement.style.background = '';
      this.uploadBox.nativeElement.style.backgroundSize = '0%';
      this.uploadBox.nativeElement.style.backgroundPosition = 'left';
      this.uploadBox.nativeElement.style.backgroundColor = background;
    }
  }

  prepareUpload(file: File) {
    this.file = file;
    const fileValidation = validateFile(file, this.maxFileLength);
    if (fileValidation.valid) {
      return true;
    } else {
      this.uploadError = fileValidation.error;
      this.hasUploadError = true;
      this.visibleButtons = false;
      return false;
    }
  }

  openCameraModal() {
    const webcamModalRef = this.modalService.open(WebcamModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });
    webcamModalRef.dismissed
      .pipe(take(1))
      .subscribe({
        next: pic => {
          if (pic) {
            this.onWebcamPhotoTaken(pic);
          }
          if (!this.cameraOpenedForUpdate) {
            this.visibleButtons = true;
          }
        },
        error: () => {
          if (!this.cameraOpenedForUpdate) {
            this.visibleButtons = true;
          }
        }
      });
  }

  onCropImage() {
    const cropModalRef = this.modalService.open(CropModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });
    cropModalRef.componentInstance.image = this.imageUrl;
    cropModalRef.dismissed
      .pipe(take(1))
      .subscribe(
        {
          next: (croppedImage: Blob) => {
            if (croppedImage) {
              this.cacheOriginalImageBeforeTransform();
              this.setCanvasBackground({
                file: new File([croppedImage], `${this.file.name}_CROPPED`, { type: 'image/jpeg' }),
                url: URL.createObjectURL(croppedImage)
              });
              this.documentPhotoUpdated.emit();
            }
          },
          error: (e) => console.log(e)
        }
      );
  }

  private async cacheOriginalImageBeforeTransform(): Promise<void> {
    if (!this.imageTransformed) {
      this.imageTransformed = true;
      this.imageTransformedChange.emit(true);
      this.cachedTransformedImage = {
        file: this.file ? this.file : await convertUrlToFile(this.imageUrl),
        url: this.imageUrl,
        poolUrl: this.poolImageUrl
      };
    }
  }

  transformImage(type: ImageTransformType): void {
    if (this.editImageState !== 'notStarted') {
      return;
    }

    this.cacheOriginalImageBeforeTransform();

    switch (type) {
      case 'mirror':
        this.editImageState = 'inEditFlip';
        break;
      case 'rotate-left':
        this.editImageState = 'inEditRotateLeft';
        break;
      case 'rotate-right':
        this.editImageState = 'inEditRotateRight';
        break;
      default:
        this.editImageState = 'notStarted';
        break;
    }
    imageTransformer(this.imageUrl, type, this.file.name).pipe(take(1)).subscribe({
      next: async (res) => {
        this.setCanvasBackground(res);
        this.editImageState = 'notStarted';
        this.completeUpload = true;
        this.documentPhotoUpdated.emit();
      },
      error: () => {
        this.editImageState = 'notStarted';
        this.completeUpload = true;
      }
    });
  }

  private setCanvasBackground({ file, url }: { file: File, url: string }): void {
    this.file = file;
    this.photo = url;
    this.imageUrl = url;
    this.setBackground(url);
    this.inputImageUrl = url;
    this.poolImageUrl = url;
  }

  onResetImageTransformations(): void {
    this.imageTransformed = false;
    this.imageTransformedChange.emit(false);
    if (this.cachedTransformedImage) {
      this.file = this.cachedTransformedImage.file;
      this.imageUrl = this.cachedTransformedImage.url;
      this.setBackground(this.cachedTransformedImage.url);
      this.inputImageUrl = this.cachedTransformedImage.url;
      this.poolImageUrl = this.cachedTransformedImage.poolUrl;
    }
    // At the moment `update-application-details` need to disable the cancel
    // button in case it got enabled just because of the image transformation
    if (!this.inputImageUrlWasNull) {
      if (this.cachedPhotoBeforeReplacement) {
        // To just reset the changes and not affect the form within the parent
        this.transformChangesReset$.next();
      } else {
        // To reset and let the parent reset the form
        this.transformChangesReset$.next(this.cachedTransformedImage?.poolUrl);
      }
    }
    this.clearTheCachedTransform();
  }

  private clearTheCachedTransform(): void {
    if (this.cachedTransformedImage) {
      this.cachedTransformedImage = null;
      this.imageTransformed = false;
      this.imageTransformedChange.emit(false);
    }
  }

  // Only when the cancel triggered from outside
  private resetToOriginalPhoto(): void {
    this.file = this.cachedPhotoBeforeReplacement.file;
    this.imageUrl = this.cachedPhotoBeforeReplacement.url;
    this.setBackground(this.cachedPhotoBeforeReplacement.url);
    this.inputImageUrl = this.cachedPhotoBeforeReplacement.url;
    this.cachedPhotoBeforeReplacement = null;
    this.hideUploadOptions();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.transformChangesReset$.complete();
  }
}
