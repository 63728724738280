<ng-container *ngIf="isLifeSkills();else notLifeSkills">
  Results expected in 7 days
</ng-container>
<ng-template #notLifeSkills>
  <ng-container *ngIf="isComputerDelivered()">
    Results expected in 1-5 days
  </ng-container>

  <ng-container *ngIf="isPaperBased()">
    Results expected in 13 days
  </ng-container>
</ng-template>

